import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CaseManagementService } from 'projects/case-manager/src/app/services/caseManagementService';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Location } from '@angular/common';
import { ItemData } from 'projects/case-manager/src/app/interfaces/item-data';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { matchTerm } from 'projects/case-manager/src/app/helpers/utility';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-case-view',
  templateUrl: './case-view.component.html',
  styleUrls: ['./case-view.component.scss'],
})
export class CaseViewComponent implements OnInit {
  actLogs = [];
  comments = [];
  roles = [];
  permissions = undefined;
  statuses = [];
  riskStatuses = [];
  resolutionStatuses = [];
  actionBar = false;
  matchingId = '';
  subscriptions: Subscription[] = [];
  caseId = '';
  searchTerm = '';
  visible = false;
  toggleDropdown = false;
  date = new FormControl(null);
  caseStatuses = [
    { label: 'All', value: 'all', checked: true },
    { label: 'Resolved', value: 'resolved', checked: false },
    { label: 'Unresolved', value: 'unresolved', checked: false },
  ];
  placement: NzDrawerPlacement = 'left';
  radioValue = 'A';
  isEnglish = false;
  selectedResolutionStatus = '';
  selectedRiskLevel = '';
  indeterminate = false;
  selectAllChecked: boolean = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  status = new FormControl(false);
  checked = new FormControl(false);
  caseData = null;
  caseDetails = null;
  case_details = null;
  isCaseModalVisible = false;
  checkedlist = [];
  resolutionForm: FormGroup;
  selectedCase = null;
  reverseArray : Boolean = false;
  resolutionReason = '';
  public openSidebar = false;
  commentId = undefined;
  commentData = [];
  review = '';
  userList = [];
  caseFilters = [];
  allCaseDetails = [];
  loading = false;
  caseDetailsLoading = false;
  selectedCaseStatus = null;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  errorMessage = '';
  case_assigned_to = '';
  userId = '';

  constructor(
    private _router: Router,
    private i18n: NzI18nService,
    private caseManagementService: CaseManagementService,
    private settingService: SettingService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private location: Location,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private globalStateService: GlobalStateService,
    private errorMessageService: ErrorMessageService,
  ) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.case_assigned_to = JSON.parse(localStorage.getItem('case_assigned_to'));
    this.userId = JSON.parse(localStorage.getItem('userID'));

    const payload = {
      user_id: userDetails.id.toString(),
    };

    this.subscriptions.push(
      // this._globalStateService.getPermissions().subscribe((temp) => {
      this.settingService.getUserPermissions(payload).subscribe((temp) => {
        if (temp.status == 'ok') {
          // this.permissions = res.permissions;
          if (temp['userPermissions'] && temp['userPermissions']['permission_tags']) {
            const permissionsArray = Array.from(new Set(temp['userPermissions']['permission_tags']));
            this.permissions = this.globalStateService.MakePermissionsArray(permissionsArray);
          }
        } else {
          this.permissions = undefined;
        }
      },
        (err) => {
          this.permissions = undefined;
        }
      )
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.caseId = params['id'];
    });

    // Related to Chat Section
    this.settingService.getUsers().subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.userList = res['users'];
        } else {
          this.userList = [];
        }
      },
      (err) => {
        this.userList = [];
      }
    );

    this.spinner.show('commentDataLoading');
    this.caseManagementService.commentId.subscribe(
      (res) => {
        if (res) {
          this.commentId = res;
          this.loading = true;
          this.subscriptions.push(
            this.caseManagementService
              .getCaseCommentsById(this.commentId)
              .subscribe(
                (response) => {
                  if (response.status == 'ok') {
                    this.loading = false;
                    this.spinner.hide('commentDataLoading');
                    this.commentData = response.comments;
                  } else {
                    this.loading = false;
                    this.spinner.hide('commentDataLoading');
                    this.commentData = [];
                  }
                },
                (err) => {
                  this.loading = false;
                  this.spinner.hide('commentDataLoading');
                  this.commentData = [];
                }
              )
          );
        } else {
          this.spinner.hide('commentDataLoading');
          this.commentData = [];
        }
      },
      (err) => {
        this.spinner.hide('commentDataLoading');
        this.commentData = [];
      }
    );
    // Related to Chat Section

    this.subscriptions.push(
      this.settingService.getChoices().subscribe((res) => {
        if (res.status == 'ok') {
          res.choices.alert_choices['CASE_STATUS_CHOICES'].map((temp) => {
            this.statuses.push({ value: temp[0], viewValue: temp[1] });
          });

          res.choices.alert_choices['CASE_RISK_LEVEL_CHOICES'].map((temp) => {
            this.riskStatuses.push({
              value: temp[0],
              viewValue: temp[1],
            });
          });

          res.choices.alert_choices['CASE_RESOLUTION_STATUS_CHOICES'].map(
            (temp) => {
              this.resolutionStatuses.push({
                value: temp[0],
                viewValue: temp[1],
              });
            }
          );
        } else {
          this.statuses = [];
          this.riskStatuses = [];
          this.resolutionStatuses = [];
        }
      })
    );

    this.subscriptions.push(
      this.caseManagementService.passData.subscribe(
        (res) => {
          if (!res) {
            this.getCaseById();
          } else {
            this.caseData = res;
            this.getCaseDetails();
          }
        },
        (err) => {
          console.log('Error in getting case details: ', err);
        }
      )
    );

    // Show error messages
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getCaseById() {
    if (this.caseId) {
      this.spinner.show('panelLoading');
      this.subscriptions.push(
        this.caseManagementService
          .listActiveCases(false, undefined, this.caseId)
          .subscribe(
            (data) => {
              if (data.status == 'ok') {
                this.spinner.hide('panelLoading');

                if (data.cases && Object.keys(data.cases).length) {
                  this.caseData = data.cases;

                  this.getCaseDetails();
                } else {
                  this.caseData = null;
                  this.spinner.hide('panelLoading');
                }
              } else {
                this.caseData = null;
                this.spinner.hide('panelLoading');
              }
            },
            (err) => {
              this.caseData = null;
              this.spinner.hide('panelLoading');
            }
          )
      );
    }
  }

  /**
   * Get Case Details by Case ID
   */
  getCaseDetails(filters?: any, ifReverse?) {
    this.spinner.show('panelLoading');
    this.caseDetailsLoading = true;
    this.subscriptions.push(
      this.caseManagementService.getCaseDetails(this.caseId, filters).subscribe(
        (data) => {
          if (data && data.caseDetails) {
            if (data.caseDetails.length) {
              this.caseData.totalCount = data.caseDetails.length;
              let resolved_count = 0;

              data.caseDetails.forEach((record) => {
                record.isResolved = record.updated_by_id ? 'Yes' : 'No';
                if (record.updated_by_id) {
                  resolved_count++;
                }
              });

              this.caseData.resolved = resolved_count;
              this.caseData.unresolved =
                data.caseDetails.length - this.caseData.resolved;
              this.caseDetails = data.caseDetails;
              if(ifReverse === true){
                this.caseDetails = data.caseDetails.reverse();
              }


              if (filters) {
                this.toastr.success(
                  `${data.caseDetails.length} record(s) found!`
                );
              }
            } else {
              this.caseData.totalCount = 0;
              this.caseData.resolved = 0;
              this.caseData.unresolved = 0;
              this.caseDetails = [];
              this.toastr.info('No record(s) found');
            }
            // this.caseData['case_details'] = data.caseDetails;

            // set all data for search purpose
            if (
              this.caseDetails &&
              this.caseDetails.length
            ) {
              this.allCaseDetails = JSON.parse(
                JSON.stringify(this.caseDetails)
              );
            }
          }
          this.spinner.hide('panelLoading');
          this.caseDetailsLoading = false;
        },
        (err) => {
          this.spinner.hide('panelLoading');
          this.caseDetailsLoading = false;
        }
      )
    );
  }

  passDataToDownloadFile(event, data) {
    event.stopPropagation();
    this.matchingId = data.id;
    // console.log(data);

    this.spinner.show('downloadFileLoader');
    let obj = {
      "match_percentage": 100,
      "match_highlights": "match_highlights",
      "name": data.name,
      "category": data.data.entity_type,
      "cnic": data.data.cnic,
      "date_of_birth": data.data.date_of_birth,
      "id": 1,
      "pep_id": data.data.pep_id ? data.data.pep_id: null,
      "es_id": data.data.es_id ? data.data.es_id: null,
      "matched_from": data.data.matched_from
      };      

    this.caseManagementService.downloadCase(obj).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.DownloadPDF(
            res.array_buffer,
            Date.now().toString()
          );
          this.spinner.hide('downloadFileLoader');

          // this._snackBar.open(
          //   'Screening completed and file downloaded successfully!',
          //   'Dismiss',
          //   {
          //     duration: 3000,
          //     horizontalPosition: this.horizontalPosition,
          //     verticalPosition: this.verticalPosition,
          //   }
          // );
          this.toastr.success(
            'File downloaded successfully!'
          );
        } else {
          this.spinner.hide('downloadFileLoader');
        }
      },
      (err) => {
        this.spinner.hide('downloadFileLoader');
      }
    );
  }

  DownloadPDF(data, name) {
    if (name) {
      let myblob = new Blob([data], {
        type: 'application/zip',
      });
      let a = document.createElement('a'),
        url = URL.createObjectURL(myblob);
      a.href = url;
      a.download = name.split(' ').join('_') + '_Report.zip';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  /**
   * Search Term in a Table
   * @param value
   */
  filterData(value: any) {
    setTimeout(() => {
      if (!value) {
        this.caseDetails = this.allCaseDetails;
        return;
      }

      this.caseDetails = this.allCaseDetails.filter((obj) => {
        return (
          matchTerm(obj, 'name', value) ||
          matchTerm(obj, 'id', value) ||
          matchTerm(obj, 'notes', value) ||
          matchTerm(obj, 'resolution_reason', value) ||
          matchTerm(obj, 'rank', value)
        );
      });
    }, 300);
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.checkedlist.push(id);
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
    this.selectAllChecked = this.caseDetails.every((data) =>
    this.setOfCheckedId.has(data.id)
    );
  }

  onSelectAllClicked() {
    this.selectAllChecked = !this.selectAllChecked;
    // Update the state of all individual checkboxes
    if (this.selectAllChecked) {
      // Select all checkboxes
      this.case_details = this.caseDetails.filter(item => item.isResolved !== 'Yes');
      this.setOfCheckedId = new Set(this.case_details.map((data) => data.id));
    } else {
      // Deselect all checkboxes
      this.setOfCheckedId.clear();
    }
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked.setValue(
      this.listOfCurrentPageData.every((item) =>
        this.setOfCheckedId.has(item.id)
      )
    );
    this.indeterminate =
    this.listOfCurrentPageData.some((item) =>
    this.setOfCheckedId.has(item.id)
    ) && !this.checked;
  }

  onChange(result: Date[]): void {
    console.log('onChange: ', result);
  }

  changeLanguage(): void {
    this.i18n.setLocale(this.isEnglish ? zh_CN : en_US);
    this.isEnglish = !this.isEnglish;
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  hideOverlay(toggleDropdown) {
    if (toggleDropdown) {
      document.body.setAttribute('class', 'hide-dropdown-overlay');
    } else {
      setTimeout(() => {
        document.body.setAttribute('class', '');
      }, 300);
    }
  }

  /**
   * Filter Cases by status
   * @param status
   */
  caseStatusChanged(status) {
    status = status.value;

    if (this.caseDetails && this.caseDetails.length) {
      if (status == 'resolved') {
        this.caseDetails = this.allCaseDetails.filter(
          (_case) => _case.isResolved == 'Yes'
        );
      } else if (status == 'unresolved') {
        this.caseDetails = this.allCaseDetails.filter(
          (_case) => _case.isResolved == 'No'
        );
      } else if (status == 'all') {
        this.caseDetails = JSON.parse(
          JSON.stringify(this.allCaseDetails)
        );
      }
    } else {
      this.caseDetails = JSON.parse(
        JSON.stringify(this.allCaseDetails)
      );
    }
  }

  TransformStatus(status) {
    let name = '';
    this.statuses.map((res) => {
      if (res.value == status) {
        name = res.viewValue;
      }
    });
    return name;
  }

  TransformRisk(risk) {
    let name = '';
    if (this.riskStatuses && this.riskStatuses.length) {
      this.riskStatuses.map((tr) => {
        if (tr.value == risk) {
          name = tr.viewValue;
        }
      });
    }
    return name;
  }

  TransformResolutionStatus(res) {
    let name = '';
    if (this.resolutionStatuses && this.resolutionStatuses.length) {
      this.resolutionStatuses.map((tr) => {
        if (tr.value == res) {
          name = tr.viewValue;
        }
      });
    }
    return name;
  }

  GotoListDetails(record) {
    this.selectedCase = record;
    this.caseManagementService.caseWithinCaseDetails.next(record);
    this.caseManagementService.previousCaseId.next(this.caseId);
    localStorage.setItem('caseId', record.case_id);
    localStorage.setItem('recordId', record.id);
    window.open(
      `/pages/case-management/case-view/record-detail/${record.id}`,
      '_blank'
    );
  }

  goBack() {
    this._router.navigate([`pages/case-management/case-list`]);
  }

  openSideMenu(event, id) {
    event.stopPropagation();
    this.openSidebar = true;
    this.caseManagementService.commentId.next(id);
  }

  closeSideMenu() {
    this.openSidebar = false;
  }

  /// Case Resolve Modal JS ///
  handleOk(): void {
    this.isCaseModalVisible = false;
    this.resolutionReason = '';
    this.selectedResolutionStatus = '';
    this.selectedRiskLevel = '';
    // this.checkedlist = [];
  }

  handleCancel(): void {
    this.isCaseModalVisible = false;
    this.resolutionReason = '';
    this.selectedResolutionStatus = '';
    this.selectedRiskLevel = '';
    // this.checkedlist = [];
  }

  resolveCase(e) {
    e.stopPropagation();
    this.isCaseModalVisible = true;
  }

  getResolutionStatus(value) {
    this.selectedResolutionStatus = value;
  }

  getRiskLevel(value) {
    this.selectedRiskLevel = value;
  }

  showIds() {
    let str = '';
    if (this.setOfCheckedId && this.setOfCheckedId.size) {
      // str = Array.from(new Set(this.checkedlist)).join(',');
      str = Array.from(this.setOfCheckedId).join(',');
    }

    return str;
  }

  getReasonOfResolution(resolution) {
    let reason = '';
    switch (resolution) {
      case 'F':
        reason = 'No Match';
        break;
      case 'P':
        reason = 'Full Match';
        break;
      case 'M':
        reason = 'Partial Match';
        break;
      case 'U':
        reason = 'Unknown';
        break;
    }
    return reason;
  }

  ResolveCase() {
    let ids = '';

    let reasonOfRes = this.getReasonOfResolution(this.selectedResolutionStatus);

    // if (this.checkedlist && this.checkedlist.length) {
    //   ids = this.checkedlist.join(',');
    // }
    
    if (this.setOfCheckedId && this.setOfCheckedId.size) {
      ids = Array.from(this.setOfCheckedId).join(',');
    }

    let obj = {
      // id: this.checkedlist && this.checkedlist.length ? ids : '',
      id: this.setOfCheckedId && this.setOfCheckedId.size ? ids : '',
      resolution_status: this.selectedResolutionStatus,
      resolution_reason: reasonOfRes,
      notes: this.resolutionReason,
    };

    if (this.selectedRiskLevel) {
      obj['risk_level'] = this.selectedRiskLevel;
    } else {
      obj['risk_level'] = 'U';
    }

    if (this.resolutionReason) {
      this.spinner.show('resolveLoading');

      this.caseManagementService.resolveACase(obj).subscribe(
        (temp) => {
          if (temp.status == 'ok') {
            this.spinner.hide('resolveLoading');
            this.isCaseModalVisible = false;

            if (obj.resolution_status != 'U') {
              this.toastr.success(
                'Case(s) resolved successfully, Wait list will update shortly!'
              );
            }
            this.caseDetails = [];
            // this.checkedlist = [];
            this.setOfCheckedId.clear();
            this.getCaseDetails();

            this.caseStatuses = [
              { label: 'All', value: 'all', checked: true },
              { label: 'Resolved', value: 'resolved', checked: false },
              { label: 'Unresolved', value: 'unresolved', checked: false },
            ];
          } else {
            // if (temp.status == 'error' && temp.code == 'HS002') {
            //   this.toastr.error(
            //     'Record cannot be resolved, case is escalated but you do not have escalation Role'
            //   );
            // } else {
            //   this.toastr.error('Error in resolving case!');
            // }
            this.spinner.hide('resolveLoading');
            this.isCaseModalVisible = false;
            this.caseManagementService.resolved.next({ status: false });
            // this.checkedlist = [];
            this.setOfCheckedId.clear();
            this.selectAllChecked = false;
            this.caseStatuses = [
              { label: 'All', value: 'all', checked: true },
              { label: 'Resolved', value: 'resolved', checked: false },
              { label: 'Unresolved', value: 'unresolved', checked: false },
            ];
          }
        },
        (err) => {
          this.spinner.hide('resolveLoading');
          this.isCaseModalVisible = false;
          // this.toastr.error('Error in resolving case!');
          this.caseStatuses = [
            { label: 'All', value: 'all', checked: true },
            { label: 'Resolved', value: 'resolved', checked: false },
            { label: 'Unresolved', value: 'unresolved', checked: false },
          ];
        }
      );
    }
  }

  // Related to Chat Sections
  TRansformUser(usr) {
    let user = '';

    this.userList.map((res) => {
      if (res.id == usr) {
        user = res.username;
      }
    });

    return user;
  }

  SubmitReview() {
    if (this.review) {
      this.spinner.show('commentSpinner');
      this.caseManagementService
        .reviewACase(this.commentId, this.review)
        .subscribe(
          (response) => {
            let res: any = response;
            if (res.status == 'ok') {
              let obj = {
                notes: this.review,
                created_by: localStorage.getItem('userID'),
                created_on: new Date().toISOString(),
              };
              this.commentData.push(obj);
              this.review = '';
              this.spinner.hide('commentSpinner');
              this.toastr.success('Review submitted successfully');
            } else {
              // this.toastr.error('Error in submitting review');
              this.spinner.hide('commentSpinner');
            }
          },
          (err) => {
            // this.toastr.error('Error in submitting review');
            this.spinner.hide('commentSpinner');
          }
        );
    }
  }
  // Related to Chat Sections

  setResolutionStatusClass(resolutionStatus) {
    const obj = {
      F: 'text-danger',
      P: 'text-success',
      U: 'text-info',
    };

    return obj[resolutionStatus];
  }

  /**
   * Handle case search filters event
   * @param event
   */
  filterCaseDetails(event) {
    this.getCaseDetails(event);
  }

  reverse(){
    this.reverseArray = !this.reverseArray;
    this.getCaseDetails(undefined,this.reverseArray);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
