import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoginService } from '../auth/services/login.service';
import { APIRoutes } from '../enums/api-routes.enum';
import { createCleanedObject } from '../helpers/utility';
import { ErrorMessageService } from './error-message.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class CaseManagementService {
  public _config = environment;
  public serverURL = this._config.serverIP;
  public commentId = new BehaviorSubject<any>(undefined);
  public dataError = new BehaviorSubject<boolean>(false);
  public actionTabRequired = new BehaviorSubject<boolean>(false);
  public errorMessageComment: BehaviorSubject<any> = new BehaviorSubject([]);
  public errorMessageForActivityLogs: BehaviorSubject<any> =
    new BehaviorSubject([]);
  public submitSearchRecordList: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );

  public goBackFromList = new BehaviorSubject<boolean>(false);
  // public sendProfileDataFromScreening = new BehaviorSubject<any>(undefined);
  public resolved = new BehaviorSubject<any>(undefined);
  public resetAction = new BehaviorSubject<boolean>(false);
  public passData: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public caseWithinCaseDetails: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );
  public previousCaseId: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public jobs: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private _authService: LoginService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private toastr: ToastrService,

  ) {}

  /**BASIC FUNCTIONS */
  setHeaders(hmacData) {
    let options;
    let user = this.getFromLocal('currentUserCaseMgt');
    if (!!user) {
      let token = user;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'jwt ' + token,
        Signature: hmacData.HMAC_Sign,
        Key: hmacData.user_key,
        timestamp: hmacData.timeStamp,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
      options = {
        headers: headers,
      };
    }
    return options;
  }

  setFileHeader(hmacData, isDownload: boolean = false) {
    let options;
    let user = this.getFromLocal('currentUserCaseMgt');
    if (!!user) {
      let token = user;
      const headers = new HttpHeaders({
        Authorization: 'jwt ' + token,
        Signature: hmacData.HMAC_Sign,
        Key: hmacData.user_key,
        timestamp: hmacData.timeStamp,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
      options = {
        headers: headers,
        responseType: isDownload ? 'arraybuffer' : 'json'
      };
    }
    //   console.log(options);

    return options;
  }

  getKeys() {
    let credentials: any;
    if (
      localStorage.getItem('userKey') &&
      localStorage.getItem('secretKey')
    ) {
      credentials = {
        userKey: localStorage.getItem('userKey'),
        secretKey: localStorage.getItem('secretKey'),
      };
    }
    return credentials;
  }

  getFromLocal(item) {
    return localStorage.getItem(item);
  }

  changePassword(password): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.changePass,
            method: 'POST',
            parameters: { password: password },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.changePass,
                { password: password },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in changing password, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in changing password, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in changing password, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  /**GETTERS */
  getCustomScreenAttr(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getCustomScreen,
            method: 'GET',
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .get(
                  this.serverURL + APIRoutes.getCustomScreen,
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;
                    if (
                      response &&
                      response.data &&
                      response.data.custom_screen_attributes &&
                      response.data.custom_screen_attributes.length
                    ) {
                      observer.next({
                        status: 'ok',
                        attrs: response.data.custom_screen_attributes,
                      });
                      observer.complete();
                    } else {
                      // this.errorMessageService.errorMessage.next(
                      //   'Something went wrong in getting custom screen attributes, Please try again later!'
                      // );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      // this.errorMessageService.errorMessage.next(
                      //   err?.error?.error ||
                      //   'Something went wrong in getting custom screen attributes, Please try again later!'
                      // );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              // this.errorMessageService.errorMessage.next(
              //   err?.error?.error ||
              //   'Something went wrong in getting custom screen attributes, Please try again later!'
              // );
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        // this.errorMessageService.errorMessage.next(
        //   'Something went wrong in getting custom screen attributes, Please try again later!'
        // );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  getAccessLogs(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getAccessLogAPI,
            method: 'GET',
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .get(
                  this.serverURL + APIRoutes.getAccessLogAPI,
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;
                    if (
                      response &&
                      response.data &&
                      response.data.access_log &&
                      response.data.access_log.length
                    ) {
                      observer.next({
                        status: 'ok',
                        accessLogs: response.data.access_log,
                      });
                      observer.complete();
                    } else {
                      this.errorMessageService.errorMessage.next(
                        'Something went wrong in getting Access Logs, Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      this.errorMessageService.errorMessage.next(
                        err?.error?.error ||
                          'Something went wrong in getting Access Logs, Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              this.errorMessageService.errorMessage.next(
                err?.error?.error ||
                  'Something went wrong in getting Access Logs, Please try again later!'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting Access Logs, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  exportCase(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.exportcases,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.exportcases,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in exporting cases, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in exporting cases, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in exporting cases, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  downloadCase(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.download_case,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.download_case,
                obj,
                this.setFileHeader(headerOptions, true),
                )
              .subscribe(
                (res) => {
                  let response: any = res;

                //   if (
                //     response &&
                //     response.data &&
                //     Object.keys(response.data).length
                //   ) {
                //     observer.next({
                //       status: 'ok',
                //       array_buffer: response,
                //     });
                //     observer.complete();
                //   } else {
                //     this.errorMessageService.errorMessage.next(
                //       'Something went wrong in downloading cases, Please try again later!'
                //     );
                //     observer.error({ status: 'error' });
                //     observer.complete();
                //   }
                // },
                // (err) => {
                //   if (err.error) {
                //     this.errorMessageService.errorMessage.next(
                //       err?.error?.error ||
                //         'Something went wrong in downloading cases, Please try again later!'
                //     );
                //     observer.error({ status: 'error' });
                //     observer.complete();
                //   }
                // }
                if (
                  response &&
                  response.error &&
                  response.error != ''
                ) {
                  let errMsg = response.error
                    ? response.error.split(':').pop()
                    : 'An error has occurred while downloading case, please try again later!';

                  this.errorMessageService.errorMessage.next([]);
                  this.errorMessageService.errorMessage.getValue().push(errMsg);
                  this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
                  observer.next({
                    status: 'error',
                    code: response.error_code,
                  });
                  observer.complete();
                } else {
                  observer.next({
                    status: 'ok',
                    array_buffer: response,
                  });
                  observer.complete();
                }
              },
              (err) => {
                let errMsg = JSON.parse(
                  String.fromCharCode.apply(null, new Uint8Array(err.error))
                );
                errMsg = errMsg.error
                  ? errMsg.error.split(':').pop()
                  : 'An error has occurred while downloading case, please try again later!';

                this.errorMessageService.errorMessage.next([]);
                this.errorMessageService.errorMessage.getValue().push(errMsg);
                this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
                observer.next({
                  status: 'error',
                  code: err.error.error_code,
                });
                observer.complete();
              }
              );
          });
          
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in downloading cases, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  // exportCases(obj, apiRoute): Observable<any> {
  //   obj = createCleanedObject(obj);

  //   return new Observable((observer) => {
  //     let keys = this.getKeys();
  //     if (keys) {
  //       this._authService
  //         .HMACGeneration(keys.userKey, keys.secretKey, {
  //           endPoint: '/' + APIRoutes[apiRoute],
  //           method: 'POST',
  //           parameters: obj,
  //         })
  //         .subscribe((res) => {
  //           let headerOptions = {
  //             HMAC_Sign: res.HMAC_Sign,
  //             user_key: res.user_key,
  //             timeStamp: res.timeStamp,
  //           };
  //           this.http
  //             .post(
  //               this.serverURL + APIRoutes[apiRoute],
  //               obj,
  //               this.setHeaders(headerOptions)
  //             )
  //             .subscribe(
  //               (res) => {
  //                 let response: any = res;

  //                 if (
  //                   response &&
  //                   response.data &&
  //                   Object.keys(response.data).length
  //                 ) {
  //                   observer.next({
  //                     status: 'ok',
  //                   });
  //                   observer.complete();
  //                 } else {
  //                   observer.next({ status: 'error' });
  //                   observer.complete();
  //                 }
  //               },
  //               (err) => {
  //                 if (err.error) {
  //                   this.errorMessageService.errorMessage
  //                     .getValue()
  //                     .push(
  //                       (err.error && err.error.error_code
  //                         ? err.error.error_code
  //                         : '') +
  //                       ': Something went wrong in exporting cases, Try again!'
  //                     );
  //                   this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
  //                   observer.next({ status: 'error' });
  //                   observer.complete();
  //                 }
  //               }
  //             );
  //         });
  //     } else {
  //       this.dataError.next(true);
  //       observer.next({ status: 'error' });
  //       observer.complete();
  //     }
  //   });
  // }

  exportCases(obj): Observable<any> {
    obj = createCleanedObject(obj);

    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/api/export_cases_report/',
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + 'api/export_cases_report/',
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      response?.error ||
                        'Something went wrong in exporting cases, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    // this.errorMessageService.errorMessage
                    //   .getValue()
                    //   .push(
                    //     (err.error && err.error.error_code
                    //       ? err.error.error_code
                    //       : '') +
                    //       ': Something went wrong in exporting cases, Try again!'
                    //   );
                    // this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in exporting cases, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in exporting cases, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  getExportList(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getExportCasesList,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.getExportCasesList,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length &&
                    response.error == ''
                  ) {
                    observer.next({
                      status: 'ok',
                      lists: response.data.reports_list,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in getting list of cases to export, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting list of cases to export, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting list of cases to export, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  createCA(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createcustomAttr,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.createcustomAttr,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                      id: response.data.id,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in creating custom attribute, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in creating custom attribute, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in creating custom attribute, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  deleteCA(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.deleteCustomAttr,
            method: 'POST',
            parameters: { id: id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.deleteCustomAttr,
                { id: id },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in deleting custom attribute, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in deleting custom attribute, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in deleting custom attribute, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  // getCaseActivityLog(id): Observable<any> {
  //   return new Observable((observer) => {
  //     let keys = this.getKeys();
  //     if (keys) {
  //       this._authService
  //         .HMACGeneration(keys.userKey, keys.secretKey, {
  //           endPoint: '/' + this.getCaseLog,
  //           method: 'POST',
  //           parameters: { case: id },
  //         })
  //         .subscribe((res) => {
  //           let headerOptions = {
  //             HMAC_Sign: res.HMAC_Sign,
  //             user_key: res.user_key,
  //             timeStamp: res.timeStamp,
  //           };
  //           this.http
  //             .post(
  //               this.serverURL + this.getCaseLog,
  //               { case: id },
  //               this.setHeaders(headerOptions)
  //             )
  //             .subscribe(
  //               (res) => {
  //                 let response: any = res;
  //                 if (response && response.data && response.data.user_activity_log && response.data.user_activity_log.length) {

  //                   observer.next({
  //                     status: 'ok',
  //                     logs: response.data.user_activity_log,
  //                   });
  //                   observer.complete();
  //                 }else{
  //                   observer.next({
  //                     status: 'ok',
  //                     logs: [],
  //                   });
  //                   observer.complete();
  //                 }
  //               },
  //               (err) => {
  //                 if (err.error) {
  //                   this.errorMessageService.errorMessage
  //                     .getValue()
  //                     .push(
  //                       (err.error && err.error.error_code
  //                         ? err.error.error_code
  //                         : '') +
  //                         ': Something went wrong in getting case details, Refresh to try again!'
  //                     );
  //                   this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
  //                   observer.next({ status: 'error' });
  //                   observer.complete();
  //                 }
  //               }
  //             );
  //         });
  //     } else {
  //       this.dataError.next(true);
  //     }
  //   });
  // }

  getCaseCommentsById(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getCaseComments,
            method: 'POST',
            parameters: { case_item: id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.getCaseComments,
                { case_item: id },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    response.data.case_detail_comments &&
                    response.data.case_detail_comments.length
                  ) {
                    observer.next({
                      status: 'ok',
                      comments: response.data.case_detail_comments,
                    });
                    observer.complete();
                  } else {
                    observer.next({
                      status: 'ok',
                      code: '404',
                      comments: [],
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageComment.next(
                      err?.error?.error ||
                        'Something went wrong in getting comments, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageComment.next(
          'Something went wrong in getting comments, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  fetchCaseActivityLogs(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.caseActivityURL,
            method: 'POST',
            parameters: { case: id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.caseActivityURL,
                { case: id },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      activityLogs: response.data.case_activity_log,
                    });

                    observer.complete();
                  } else {
                    this.errorMessageForActivityLogs.next(
                      response?.data?.error ||
                        'Something went wrong in getting case activity logs, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageForActivityLogs.next(
                      err?.error?.error ||
                        'Something went wrong in getting case activity logs, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageForActivityLogs.next(
          'Something went wrong in getting case activity logs, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  getCaseDetails(id, filters): Observable<any> {
    let passingData = {};
    if (filters && Object.keys(filters).length) {
      let obj = createCleanedObject(filters);
      passingData = {
        case: id,
        filters: obj,
      };
    } else {
      passingData = {
        case: id,
      };
    }
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getCaseDetail,
            method: 'POST',
            parameters: passingData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.getCaseDetail,
                passingData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      caseDetails: response.data.case_details,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in getting case details, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting case details, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting case details, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  // getCaseChannelDetails(): Observable<any> {
  //   return new Observable((observer) => {
  //     let keys = this.getKeys();
  //     if (keys) {
  //       this._authService
  //         .HMACGeneration(keys.userKey, keys.secretKey, {
  //           endPoint: '/' + APIRoutes.getCaseChannelList,
  //           method: 'GET',
  //         })
  //         .subscribe((res) => {
  //           let headerOptions = {
  //             HMAC_Sign: res.HMAC_Sign,
  //             user_key: res.user_key,
  //             timeStamp: res.timeStamp,
  //           };
  //           this.http
  //             .get(
  //               this.serverURL + APIRoutes.getCaseChannelList,
  //               this.setHeaders(headerOptions)
  //             )
  //             .subscribe(
  //               (res) => {
  //                 let response: any = res;

  //                 if (
  //                   response &&
  //                   response.data &&
  //                   Object.keys(response.data).length
  //                 ) {
  //                   observer.next({
  //                     status: 'ok',
  //                     channels:
  //                       response.data &&
  //                         response.data.case_channels_list &&
  //                         response.data.case_channels_list.length
  //                         ? response.data.case_channels_list
  //                         : [],
  //                   });
  //                   observer.complete();
  //                 } else {
  //                   observer.next({
  //                     status: 'ok',
  //                     channels: [],
  //                   });
  //                   observer.complete();
  //                 }
  //               },
  //               (err) => {
  //                 if (err.error) {
  //                   this.errorMessageService.errorMessage
  //                     .getValue()
  //                     .push(
  //                       (err.error && err.error.error_code
  //                         ? err.error.error_code
  //                         : '') +
  //                       ': Something went wrong in getting case channels, Refresh to try again!'
  //                     );
  //                   this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
  //                   observer.next({ status: 'error' });
  //                   observer.complete();
  //                 }
  //               }
  //             );
  //         });
  //     } else {
  //       this.dataError.next(true);
  //       observer.next({ status: 'error' });
  //       observer.complete();
  //     }
  //   });
  // }

  listActiveCases(filterRequired, filters?: any, id?: any): Observable<any> {
    let passingData = {};
    if (filterRequired) {
      let obj = createCleanedObject(filters);
      obj['query_filter'] = filterRequired;
      passingData = obj;
    } else {
      passingData = {
        query_filter: filterRequired,
      };
    }

    if (id && id != undefined) {
      passingData['case_id'] = id;
    }
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listCases,
            method: 'POST',
            parameters: passingData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.listCases,
                passingData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (response && response.data && response.error == '') {
                    observer.next({
                      status: 'ok',
                      cases:
                        response.data && response.data.user_details
                          ? response.data.user_details
                          : undefined,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in getting active cases, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting active cases, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting active cases, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  nameSearch(param?: any): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      // let screenAPI = param.zerofp ? APIRoutes.zeroFootprintAPI : APIRoutes.screencase;
      let screenAPI = APIRoutes.screencase;

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + screenAPI,
            method: 'POST',
            parameters: { ...param },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + screenAPI,
                { ...param },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data) &&
                    response.data['alert_case'] != 'failed'
                  ) {
                    observer.next({
                      status: 'ok',
                      usercase: response.data,
                    });
                    observer.complete();
                  } else {
                    if(response.error.includes('case_id')){
                      response.error = response.error.replace(/case_id/g, "Case ID");
                    }
                    this.errorMessageService.errorMessage.next(
                      response?.error ||
                        'Something went wrong in screening / creating case, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    if(err?.error?.error === 'query_name:query_name is not provided'){
                      err.error.error = 'Name should not contain special characters';
                    }
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in screening / creating case, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in screening / creating case, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  createJobForBatchSearch(fileData, file): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      let hmacData = {
        upload_file_name: fileData,
      };

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createJobAPI,
            method: 'POST',
            parameters: { ...hmacData },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };

            let formData: FormData = new FormData();
            formData.append('upload_file_name', file);

            this.http
              .post(
                this.serverURL + APIRoutes.createJobAPI,
                formData,
                this.setFileHeader(headerOptions, false)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data) &&
                    response.data['alert_case'] != 'failed'
                  ) {
                    observer.next({
                      status: 'ok',
                      usercase: response.data,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      response?.error ||
                        'Something went wrong in screening / creating job for batch, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in screening / creating job for batch, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in screening / creating job for batch, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  getBatchSearchData(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getStatusAPI,
            method: 'POST',
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .post(
                  this.serverURL + APIRoutes.getStatusAPI,
                  null,
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;
                    if (response && response.data) {
                      observer.next({
                        status: 'ok',
                        jobs: response.data,
                      });
                      observer.complete();
                    } else {
                      this.errorMessageService.errorMessage.next(
                        'Something went wrong in getting batch search results, Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      this.errorMessageService.errorMessage.next(
                        err?.error?.error ||
                          'Something went wrong in getting batch search results, Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              this.errorMessageService.errorMessage.next(
                err?.error?.error ||
                  'Something went wrong in getting batch search results, Please try again later!'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting batch search results, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  getCaseChannelById(channelId): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      let payload = {
        id: channelId,
      };

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getCaseChannelAPI,
            method: 'POST',
            parameters: { ...payload },
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .post(
                  this.serverURL + APIRoutes.getCaseChannelAPI,
                  payload,
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;
                    if (response && response.data) {
                      observer.next({
                        status: 'ok',
                        jobs: response.data,
                      });
                      observer.complete();
                    } else {
                      this.errorMessageService.errorMessage.next(
                        'Something went wrong in getting case filters, Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      this.errorMessageService.errorMessage.next(
                        err?.error?.error ||
                          'Something went wrong in getting case filters, Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              this.errorMessageService.errorMessage.next(
                err?.error?.error ||
                  'Something went wrong in getting case filters, Please try again later!'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting case filters, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  // caseCreate(param?: any): Observable<any> {
  //   return new Observable((observer) => {
  //     let keys = this.getKeys();

  //     if (keys) {
  //       this._authService
  //         .HMACGeneration(keys.userKey, keys.secretKey, {
  //           endPoint: '/' + this.screencase,
  //           method: 'POST',
  //           parameters: { ...param },
  //         })
  //         .subscribe((res) => {
  //           let headerOptions = {
  //             HMAC_Sign: res.HMAC_Sign,
  //             user_key: res.user_key,
  //             timeStamp: res.timeStamp,
  //           };
  //           this.http
  //             .post(
  //               this.serverURL + this.screencase,
  //               { ...param },
  //               this.setHeaders(headerOptions)
  //             )
  //             .subscribe(
  //               (res) => {
  //                 let response: any = res;
  //                 if (response && response.data) {
  //                   observer.next({
  //                     status: 'ok',
  //                     usercase: response.data,
  //                   });
  //                   observer.complete();
  //                 }
  //               },
  //               (err) => {
  //                 if (err.error) {
  //                   this.errorMessageService.errorMessage
  //                     .getValue()
  //                     .push(
  //                       (err.error && err.error.error_code
  //                         ? err.error.error_code
  //                         : '') +
  //                         ': Something went wrong in screening and creating case, Please try again later!'
  //                     );
  //                   this.errorMessageService.errorMessage.next(this.errorMessageService.errorMessage.getValue());
  //                   observer.next({ status: 'error' });
  //                   observer.complete();
  //                 }
  //               }
  //             );
  //         });
  //     } else {
  //       this.dataError.next(true);
  //       observer.next({ status: 'error' });
  //       observer.complete();
  //     }
  //   });
  // }

  deleteACase(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.deleteCase,
            method: 'POST',
            parameters: { id: id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.deleteCase,
                { id: id },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length
                    && response.error === ''
                  ) {
                    observer.next({
                      status: 'ok',
                      rejectedCases:
                        response.data.rejected_cases &&
                        response.data.rejected_cases.length
                          ? response.data.rejected_cases.join(',')
                          : '',
                          code: response.error_code ? response.error_code : '',
                        });
                    observer.complete();
                  } else {
                    // this.errorMessageService.errorMessage.next(
                    //   response?.error ||
                    //     'Something went wrong in deleting case(s), Please try again later!'
                    // );
                    observer.error({ status: 'error', error: response.error });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in deleting case(s), Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in deleting case(s), Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  archieveACase(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.archeiveCase,
            method: 'POST',
            parameters: { id: id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.archeiveCase,
                { id: id },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length &&
                    response.error == ''
                  ) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      response?.error ||
                        'Something went wrong in archiving case(s), Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in archiving case(s), Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in archiving case(s), Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  assignCase(id, assigned_to) {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.assign_case,
            method: 'PUT',
            parameters: { case: id, user: assigned_to },
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .put(
                  this.serverURL + APIRoutes.assign_case,
                  { case: id, user: assigned_to },
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;
                    if (
                      response &&
                      response.data &&
                      Object.keys(response.data).length &&
                      response.error == ''
                    ) {
                      observer.next({
                        status: 'ok',
                      });
                      observer.complete();
                    } else {
                      this.errorMessageService.errorMessage.next(
                        response?.error ||
                          'Something went wrong in assigning case(s), Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      this.errorMessageService.errorMessage.next(
                        err?.error?.error ||
                          'Something went wrong in assigning case(s), Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              this.errorMessageService.errorMessage.next(
                err?.error?.error ||
                  'Something went wrong in assigning case(s), Please try again later!'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in assigning case(s), Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  escalateCase(id) {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.escalate_case,
            method: 'POST',
            parameters: { case_id: id },
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .post(
                  this.serverURL + APIRoutes.escalate_case,
                  { case_id: id },
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;

                    if (
                      response &&
                      response.data &&
                      Object.keys(response.data).length &&
                      response.error == ''
                    ) {
                      observer.next({
                        status: 'ok',
                      });
                      observer.complete();
                    } else {
                      this.errorMessageService.errorMessage.next(
                        response?.error ||
                          'Something went wrong in escalating case(s), Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      this.errorMessageService.errorMessage.next(
                        err?.error?.error ||
                          'Something went wrong in escalating case(s), Please try again later!'
                      );
                      observer.error({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              this.errorMessageService.errorMessage.next(
                err?.error?.error ||
                  'Something went wrong in escalating case(s), Please try again later!'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in escalating case(s), Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  resolveACase(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.resolveCase,
            method: 'PUT',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .put(
                this.serverURL + APIRoutes.resolveCase,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length &&
                    response.error == ''
                  ) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      response?.error ||
                        'Something went wrong in resolving case(s), Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in resolving case(s), Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in resolving case(s), Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  reviewACase(id, comment): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.submitComment,
            method: 'POST',
            parameters: { case_item: id, notes: comment },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.submitComment,
                { case_item: id, notes: comment },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                    });
                    observer.complete();
                  } else {
                    this.errorMessageComment.next(
                      response?.error ||
                        'Something went wrong in submitting a review, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageComment.next(
                      err?.error?.error ||
                        'Something went wrong in submitting a review, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageComment.next(
          'Something went wrong in submitting a review, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }
}
